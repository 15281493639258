.linkWrapper {
  border-top-width: 1px;
}

.linkText {
  word-break: break-word;
}

.linkArrow {
  transform: translateY(-50%);
}

@media (--v-from-md) {
  .adaptiveLayoutLinkWrapper {
    flex-basis: 49%;
  }

  .adaptiveLayoutLinkWrapper:nth-of-type(2) {
    border-top: 1px solid var(--v-color-ornament-primary);
  }
}

@media (--v-only-md) {
  .showLinksUnderneathOnMediumLinkList {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .showLinksUnderneathOnMediumLinkWrapper {
    flex-basis: 49%;
  }

  .showLinksUnderneathOnMediumLinkWrapper:nth-of-type(2) {
    border-top: 1px solid var(--v-color-ornament-primary);
  }
}
